<script>
	import "@chrisoakman/chessboardjs/dist/chessboard-1.0.0.min";
	import "@chrisoakman/chessboardjs/dist/chessboard-1.0.0.min.css";
	import { Chess } from "chess.js";
	import { onDestroy, onMount } from "svelte";
	import { dbGameFen, user } from "../database";
	import { listenOnFirebaseKey } from "../utils";
	import { Confetti } from "../utils/confetti";
	export let whiteUser;
	export let blackUser;
	let gameFen;
	let board;
	let gameStatusText = "";
	const game = new Chess();
	var confettiTimeOut;

	function updateGameStatusText() {
		let str = "";
		let ended = false;
		if (game.turn() == "w") {
			str = "White";
		} else {
			str = "Black";
		}
		if (game.in_checkmate()) {
			ended = true;
			str += " in checkmate!";
		} else if (game.in_stalemate()) {
			ended = true;
			str += " in stalemate";
		} else if (game.in_draw()) {
			ended = true;
			str = "draw";
		} else if (game.in_check()) {
			str += " in check";
		} else if (game.turn() == "w") {
			str = "White's turn";
		} else if (game.turn() == "b") {
			str = "Black's turn";
		}
		gameStatusText = str;
		if (ended) {
			Confetti.startSpawning();
			clearTimeout(confettiTimeOut);
			confettiTimeOut = setTimeout(function () {
				Confetti.stopSpawning();
			}, 5000);
		}
	}

	listenOnFirebaseKey(
		dbGameFen,
		val => {
			if (gameFen !== val) {
				gameFen = val;
				board.position(gameFen);
				game.load(gameFen);
				updateGameStatusText();
			}
		},
		() => {
			dbGameFen.set(game.fen());
			updateGameStatusText();
		}
	);

	onMount(() => {
		function onDragStart(source, piece, position, orientation) {
			//   do not pick up pieces if the game is over
			if (game.game_over()) return false;
			// only pick up pieces for the side to move
			if (
				(game.turn() === "w" && piece.search(/^b/) !== -1) ||
				(game.turn() === "b" && piece.search(/^w/) !== -1)
			) {
				return false;
			}
			if (game.turn() === "w" && user.id !== whiteUser?.id) {
				return false;
			}
			if (game.turn() === "b" && user.id !== blackUser?.id) {
				return false;
			}
		}

		function onDrop(source, target) {
			// see if the move is legal
			const move = game.move({
				from: source,
				to: target,
				promotion: "q", // NOTE: always promote to a queen for example simplicity
			});
			// illegal move
			// debugger;
			if (move === null) return "snapback";
			// if (turn !== game.turn()) return "snapback";
		}

		// // update the board position after the piece snap
		// // for castling, en passant, pawn promotion
		function onSnapEnd() {
			dbGameFen.set(game.fen());
		}

		const config = {
			draggable: true,
			position: "start",
			onDragStart: onDragStart,
			onDrop: onDrop,
			onSnapEnd: onSnapEnd,
		};
		board = Chessboard("board1", config);
	});
	let styles = {};
	resize();
	function resize(bool) {
		const innerSpace = window.innerWidth - 260 * 2;
		const minDimension = Math.min(window.innerHeight - 150, innerSpace);
		const width = Math.max(minDimension, 280);
		styles = {
			width: `${width}px`,
		};
		bool && board.resize();
	}
	const resizeFn = () => resize(true);
	onMount(() => {
		window.addEventListener("resize", resizeFn);
	});
	onDestroy(() => {
		window.removeEventListener("resize", () => resizeFn);
	});

	$: cssVarStyles = Object.entries(styles)
		.map(([key, value]) => `--${key}:${value}`)
		.join(";");
</script>

<div class="board-box" style={cssVarStyles}>
	<div class="status">{gameStatusText}</div>
	<div id="board1" class="board" />
</div>

<style>
	.board-box {
		position: relative;
	}
	.board {
		flex: 1;
		width: var(--width, "400px");
	}
	.status {
		font-size: 24px;
		padding-top: 8px;
		position: absolute;
		color: #ffffff;
		left: 0;
		top: -56px;
		right: 0;
		text-align: center;
	}
	@media screen and (max-width: 800px) {
		.status {
			position: static;
			font-size: 12px;
		}
	}
</style>
