<script>
	import { dbBlackUser, dbWhiteUser, user } from "../database";
	import { listenOnFirebaseKey } from "../utils";
	import Chess from "./Chess.svelte";

	let whiteUser;
	let blackUser;
	// let turn;
	let gameState = "pending";
	listenOnFirebaseKey(dbWhiteUser, val => (whiteUser = val));
	listenOnFirebaseKey(dbBlackUser, val => (blackUser = val));
	// listenOnFirebaseKey(dbTurn, (val) => (turn = val));

	$: gameState = whiteUser?.id && blackUser?.id ? "started" : "pending";

	$: whiteText = whiteUser ? (whiteUser?.id === user.id ? "You" : whiteUser.userName) : "Click to choose WHITE";

	$: blackText = blackUser ? (blackUser?.id === user.id ? "You" : blackUser.userName) : "Click to choose BLACK";

	function onWhiteClick() {
		if (!whiteUser && gameState !== "started" && blackUser?.id !== user.id) {
			dbWhiteUser.once("value", snap => {
				if (!snap.exists()) {
					dbWhiteUser.update(user);
				}
			});
		}
	}
	function onBlackClick() {
		if (!blackUser && gameState !== "started" && whiteUser?.id !== user.id) {
			dbBlackUser.once("value", snap => {
				if (!snap.exists()) {
					dbBlackUser.update(user);
				}
			});
		}
	}
</script>

<div class="main">
	<div class="white-side">
		<div class="white-box" on:click={onWhiteClick} class:cursor-pointer={!whiteUser}>
			<div class="white-logo" />
			<div class="white-text">{whiteText}</div>
		</div>
	</div>
	<div class="game-box">
		<Chess {whiteUser} {blackUser} />
	</div>
	<div class="black-side" on:click={onBlackClick} class:cursor-pointer={!blackUser}>
		<div class="black-box">
			<div class="black-logo" />
			<div class="black-text">{blackText}</div>
		</div>
	</div>
</div>

<style>
	.cursor-pointer {
		cursor: pointer;
	}
	.main {
		display: flex;
		flex: 1;
		height: 100%;
		align-items: center;
	}
	@media screen and (max-width: 800px) {
		.main {
			flex-direction: column-reverse;
		}
	}
	.game-box {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.white-side,
	.black-side {
		flex: 1;
		display: flex;
		justify-content: center;
	}
	@media screen and (max-width: 800px) {
		.white-side,
		.black-side {
			align-items: center;
		}
	}
	.white-text,
	.black-text {
		max-width: 180px;
	}
	.white-logo {
		margin: 0px auto 8px;
		background-image: url("/img/chesspieces/wikipedia/white.svg");
		background-repeat: no-repeat;
		background-size: contain;
		height: 80px;
		width: 80px;
	}
	.white-box,
	.black-box {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 100%;
		padding: 20px;
		min-height: 170px;
		min-width: 180px;
		text-align: center;
		background: #534862;
		border-radius: 8px;
		font-size: 23px;
		margin: 0px;
		color: #ffffff;
	}
	@media screen and (max-width: 800px) {
		.white-box,
		.black-box {
			height: inherit;
			padding: 0 10px;
			min-height: 70px;
			min-width: 80px;
			font-size: 12px;
			max-height: 70px;
		}
	}
	.black-logo {
		margin: 0px auto 8px;
		background-image: url("/img/chesspieces/wikipedia/black.svg");
		background-repeat: no-repeat;
		background-size: contain;
		height: 80px;
		width: 80px;
	}
	@media screen and (max-width: 800px) {
		.white-logo,
		.black-logo {
			min-height: 30px;
			height: 30px;
			min-width: 30px;
			width: 30px;
		}
	}
</style>
