import firebase from "firebase/app";
import "firebase/database";
import { getGameSessionId, getParams } from "./utils";

const firebaseConfig = {
	apiKey: process.env.API_KEY,
	authDomain: process.env.AUTH_DOMAIN,
	databaseURL: process.env.DATABASE_URL,
	projectId: process.env.PROJECT_ID,
	storageBucket: process.env.STORAGE_BUCKET,
	messagingSenderId: process.env.MESSAGING_SENDER_ID,
	appId: process.env.APP_ID,
};

firebase.initializeApp(firebaseConfig);

if (process.env.EMULATE) {
	const firebaseEmulators = {
		database: {
			host: "localhost",
			port: 9000,
		},
		functions: {
			host: "localhost",
			port: 5001,
		},
	};
	console.log("Automatically connecting Firebase SDKs to running emulators:");
	Object.keys(firebaseEmulators).forEach(function (key) {
		console.log("\t" + key + ": http://" + firebaseEmulators[key].host + ":" + firebaseEmulators[key].port);
	});
	if (firebaseEmulators.database && typeof firebase.database === "function") {
		firebase.database().useEmulator(firebaseEmulators.database.host, firebaseEmulators.database.port);
	}
	if (firebaseEmulators.firestore && typeof firebase.firestore === "function") {
		firebase.firestore().useEmulator(firebaseEmulators.firestore.host, firebaseEmulators.firestore.port);
	}
	if (firebaseEmulators.functions && typeof firebase.functions === "function") {
		firebase.functions().useEmulator(firebaseEmulators.functions.host, firebaseEmulators.functions.port);
	}
	if (firebaseEmulators.auth && typeof firebase.auth === "function") {
		firebase.auth().useEmulator("http://" + firebaseEmulators.auth.host + ":" + firebaseEmulators.auth.port);
	}
} else {
	console.log(
		"To automatically connect the Firebase SDKs to running emulators, replace '/__/firebase/init.js' with '/__/firebase/init.js?useEmulator=true' in your index.html"
	);
}

export const dbRoot = firebase.database().ref("chess");
export const dbGameSession = dbRoot.child(getGameSessionId());
export const dbWhiteUser = dbGameSession.child("whiteUser");
export const dbGameFen = dbGameSession.child("fen");
export const dbBlackUser = dbGameSession.child("blackUser");
export const dbUsers = dbGameSession.child("users");
export const dbTurn = dbGameSession.child("turn");
export const dbUser = dbUsers.child(getParams("userId"));
export const user = {
	id: getParams("userId"),
	userName: getParams("userName"),
	profilePicture: getParams("userProfilePicture"),
};

const connectedRef = firebase.database().ref(".info/connected");
connectedRef.on("value", snap => {
	if (snap.val() === true) {
		dbUser.update({
			online: true,
		});
		dbUser.onDisconnect().update({
			online: firebase.database.ServerValue.TIMESTAMP,
		});
	}
});

dbUser.update(user);
