export const params = new URLSearchParams(window.location.search);
export const getParams = function (name) {
	return "" + params.get(name);
};
export const getGameSessionId = function () {
	return getParams("roomId") + "+" + getParams("sessionId");
};

export function listenOnFirebaseKey(firebaseKey, callback, notExitCallback) {
	firebaseKey.on("value", snap => {
		if (snap.exists()) {
			callback(snap.val());
		} else {
			notExitCallback && notExitCallback();
		}
	});
}
